import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, unref as _unref, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2ccd46f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "device-page"
};
const _hoisted_2 = {
  class: "ws_table"
};
import { onMounted, ref, watch, onUnmounted } from 'vue';
import AdvancedQueryLayout from 'common/components/advanced-query-layout';
import moment from 'moment';
import { CODE_ENUM, ORDER_STATUS_CODE, formatFormTime, transformNumber } from 'common';
import { queryTradeorderPage, queryPackageList } from '@/api';

// const statusTextMap = {
//   [ORDER_STATUS_CODE.ORDER_UNPAY]: '未f付款',
//   [ORDER_STATUS_CODE.ORDER_PAY_FINISH]: '已f付款',
//   [ORDER_STATUS_CODE.ORDER_CANCLE]: '已取消'
// };

export default {
  __name: 'index',
  setup(__props) {
    const statusTagMap = {
      [ORDER_STATUS_CODE.ORDER_UNPAY]: 'warning',
      [ORDER_STATUS_CODE.ORDER_PAY_FINISH]: 'success',
      [ORDER_STATUS_CODE.ORDER_CANCLE]: 'info'
    };
    const statusOps = [{
      label: '未付款',
      value: ORDER_STATUS_CODE.ORDER_UNPAY
    }, {
      label: '已付款',
      value: ORDER_STATUS_CODE.ORDER_PAY_FINISH
    }, {
      label: '已取消',
      value: ORDER_STATUS_CODE.ORDER_CANCLE
    }];
    const advancedRef = ref();
    const tableData = ref([]);
    const formInline = ref({
      productKey: '',
      time: []
    });
    const pageParams = ref({
      curPage: 1,
      pageSize: 10
    });
    const total = ref(0);
    const handleSizeChange = () => {
      pageParams.value.curPage = 1;
      fetchList();
    };
    const handleCurrentChange = () => {
      fetchList();
    };
    const col = [{
      prop: 'tradeOrderNo',
      label: '订单编号'
    }, {
      prop: 'deviceNo',
      label: '设备编号'
    }, {
      prop: 'productName',
      label: '产品名称'
    }, {
      prop: 'packageName',
      label: '套餐名称',
      formatter: ({
        packageName,
        chargModeValue
      }) => {
        return packageName + chargModeValue;
      }
    }, {
      prop: 'chargModeNum',
      label: '生效周期'
    }, {
      prop: 'tradingAmount',
      label: '订单金额（元）',
      width: 170,
      className: 'align-right',
      formatter: ({
        tradingAmount
      }) => {
        return transformNumber(tradingAmount);
      }
    }, {
      prop: 'username',
      label: '会员账号'
    }, {
      prop: 'tradeOrderTime',
      label: '创建时间',
      formatter: ({
        tradeOrderTime
      }) => {
        return moment(tradeOrderTime).format('YYYY-MM-DD HH:mm:ss');
      }
    }];
    const getMoTime = keepAliveData => {
      return {
        ...formatFormTime(keepAliveData.time, ['startTime', 'endTime'])
      };
    };
    const tableLoading = ref(false);
    const fetchList = async queryData => {
      const keepAliveData = advancedRef.value.getKeepAliveData();
      const {
        data,
        reset
      } = queryData || {};
      if (typeof reset === 'boolean') {
        pageParams.value.curPage = 1;
      }
      tableLoading.value = true;
      const res = await queryTradeorderPage({
        ...pageParams.value,
        ...data,
        ...keepAliveData,
        ...getMoTime(keepAliveData)
      }).finally(() => {
        tableLoading.value = false;
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        var _res$data, _res$data2;
        tableData.value = (res === null || res === void 0 || (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.records) || [];
        total.value = (res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.total) || 0;
      }
    };
    onMounted(() => {
      fetchList();
    });
    const packageList = ref([]);
    const querypackageTreeList = async () => {
      const res = await queryPackageList();
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        packageList.value = res.data;
      }
    };
    querypackageTreeList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_empty = _resolveComponent("el-empty");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(AdvancedQueryLayout), {
        class: "advance-container",
        ref_key: "advancedRef",
        ref: advancedRef,
        model: formInline.value,
        onFetchList: fetchList,
        border: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "订单编号",
          prop: "tradeOrderNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formInline.value.tradeOrderNo,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formInline.value.tradeOrderNo = $event),
            clearable: "",
            maxlength: "50",
            class: "ws_select"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "设备编号",
          prop: "deviceNo"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formInline.value.deviceNo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formInline.value.deviceNo = $event),
            clearable: "",
            maxlength: "50",
            class: "ws_select"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "套餐",
          prop: "packageId"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formInline.value.packageId,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formInline.value.packageId = $event),
            class: "ws_select",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(packageList.value, item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "会员账号",
          prop: "username"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: formInline.value.username,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formInline.value.username = $event),
            clearable: "",
            maxlength: "50",
            class: "ws_select"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "订单状态",
          prop: "tradeStateCode"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: formInline.value.tradeStateCode,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formInline.value.tradeStateCode = $event),
            class: "ws_select",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(statusOps, item => {
              return _createVNode(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]);
            }), 64))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "时间",
          prop: "time",
          class: "time"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: formInline.value.time,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formInline.value.time = $event),
            "unlink-panels": "",
            type: "datetimerange",
            "range-separator": "-",
            "start-placeholder": "开始时间",
            "end-placeholder": "结束时间",
            editable: false
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "table",
        data: tableData.value
      }, {
        empty: _withCtx(() => [_createVNode(_component_el_empty, {
          description: "暂无数据"
        })]),
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(col, (item, index) => {
          return _createVNode(_component_el_table_column, _mergeProps({
            key: index,
            ref_for: true
          }, {
            ...item
          }, {
            "show-overflow-tooltip": ""
          }), null, 16);
        }), 64)), _createVNode(_component_el_table_column, {
          label: "订单状态",
          width: "160"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_tag, {
            type: statusTagMap[scope.row.orderStateCode]
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.orderStateValue), 1)]),
            _: 2
          }, 1032, ["type"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, tableLoading.value]]), _createVNode(_component_el_pagination, {
        class: "ws_mt50",
        "current-page": pageParams.value.curPage,
        "onUpdate:currentPage": _cache[6] || (_cache[6] = $event => pageParams.value.curPage = $event),
        "page-size": pageParams.value.pageSize,
        "onUpdate:pageSize": _cache[7] || (_cache[7] = $event => pageParams.value.pageSize = $event),
        "page-sizes": _ctx.PAGE_SIZES,
        layout: "->, total, sizes, prev, pager, next, jumper ",
        total: total.value,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "page-sizes", "total"])])]);
    };
  }
};